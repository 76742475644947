<template>
  <div class="home">
    <!-- <vue-easy-lightbox
      :visible="visibleRef"
      :imgs="imgsRef"
      :scrollDisabled="false"
      escDisabled
      moveDisabled
      :index="indexRef"
      @hide="onHide"
    ></vue-easy-lightbox> -->

    <div
      v-if="false"
      id="main-slider"
      data-delay="5000"
      data-animation="cross"
      class="slider w-slider"
      data-autoplay="true"
      data-easing="ease"
      data-hide-arrows="false"
      data-disable-swipe="true"
      data-autoplay-limit="0"
      data-nav-spacing="3"
      data-duration="800"
      data-infinite="true"
    >
      <div class="w-slider-mask">
        <div
          class="slide w-slide"
          v-for="item in slideData"
          :key="item"
          :style="{
            'background-image': 'url(' + item.desktopImage + ')',
            'background-position': item.textRight ? '10% 0' : '90% 0',
          }"
        >
          <!-- screenSize ? 'none' : -->
          <div class="slidercontainer w-container">
            <div class="slidediv" :class="{ setRight: item.textRight }">
              <p class="slidepara">“{{ item.quote }}”<br /></p>
              <p class="slideparaend">
                - {{ item.saidBy }},<br />{{ item.designation }}
              </p>
            </div>
            <!-- <img class="slideImage" :src="item.mobileImage" /> -->
          </div>
        </div>
      </div>
      <div class="left-arrow w-slider-arrow-left">
        <div class="w-icon-slider-left"></div>
      </div>
      <div class="right-arrow w-slider-arrow-right">
        <div class="w-icon-slider-right"></div>
      </div>
      <div class="slide-nav w-slider-nav w-round"></div>
    </div>

    <Splide
      id="main-slider"
      class="slider"
      :options="{
        drag: false,
        gap: 0,
        perPage: 1,
        perMove: 1,
        type: 'fade',
        arrows: false,
        autoplay: true,
        interval: 6000,
        pauseOnHover: false,
        easing: 'linear',
      }"
    >
      <SplideSlide v-for="(item, ind) in $store.state.slides" :key="ind">
        <div
          class="slide"
          :style="{
            'background-image': 'url(' + item.desktopImage + ')',
            'background-position': item.textRight ? '10% 0' : '90% 0',
          }"
        >
          <!-- screenSize ? 'none' : -->
          <div class="slidercontainer w-container">
            <div class="slidediv" :class="{ setRight: item.textRight }">
              <p class="slidepara">“{{ item.quote }}”<br /></p>
              <p class="slideparaend">
                - {{ item.saidBy }},<br />{{ item.designation }}
              </p>
            </div>
            <!-- <img class="slideImage" :src="item.mobileImage" /> -->
          </div>
        </div>
      </SplideSlide>
    </Splide>
    <div class="my-lightbox" v-if="showLightbox">
      <Splide
        ref="main"
        class="my-lightbox-slider"
        :options="{
          type: 'slide',
          pagination: false,
          arrows: true,
        }"
        @splide:move="handleMove"
      >
        <SplideSlide
          v-for="(item, ind) in lightboxData"
          :key="ind"
          @click="showLightbox = false"
        >
          <!-- :class="{ 'my-lightbox-slide-wrapper': item.type == 'image' }" -->
          <div class="my-lightbox-slide-wrapper">
            <img
              v-if="item.type == 'image'"
              :src="item.url"
              loading="lazy"
              alt=""
              @click.stop
            />
            <div v-else style="width: 940px">
              <div class="my-lightbox-spinner"></div>
              <!-- <figure v-html="item.html"></figure> -->
              <iframe
                :id="`iframe${ind}`"
                class="lightbox-iframe"
                frameborder="0"
                allow="autoplay; fullscreen"
                :src="item.originalUrl"
                style="width: 100%; aspect-ratio: 16/9"
              ></iframe>
              <!-- <video type="video" style="width: 100%; aspect-ratio: 16/9" controls autoplay>
                <source :src="item.originalUrl" type="video/mp4" />
              </video> -->
            </div>
          </div>
        </SplideSlide>
      </Splide>
      <div
        class="w-lightbox-control w-lightbox-close"
        @click="showLightbox = false"
      ></div>
      <Splide
        ref="thumbnails"
        class="my-lightbox-thumbnail"
        :options="{
          type: 'slide',
          isNavigation: true,
          gap: 10,
          pagination: false,
          arrows: false,
          autoWidth: true,
        }"
      >
        <SplideSlide v-for="(item, ind) in lightboxData" :key="ind">
          <div class="my-lightbox-thumbnail-item">
            <img :src="item.url" loading="lazy" alt="" />
          </div>
        </SplideSlide>
      </Splide>
    </div>

    <section class="storysection wf-section" id="news">
      <div class="container-10 w-container">
        <div
          data-delay="4000"
          data-animation="slide"
          class="storyslider w-slider"
          data-autoplay="false"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="3"
          data-duration="500"
          data-infinite="true"
        >
          <div class="w-slider-mask">
            <div class="storyslide w-slide">
              <div class="storyslidewrapper">
                <div class="storyimagediv">
                  <a href="#" class="lightbox-link w-inline-block w-lightbox"
                    ><img
                      src="images/a2img1.png"
                      loading="lazy"
                      sizes="100vw"
                      srcset="
                        images/a2img1-p-500.png 500w,
                        images/a2img1.png       535w
                      "
                      alt=""
                      class="sliderimage"
                    />
                    <!-- <script type="application/json" class="w-json">
                      {
                        "items": [
                          {
                            "_id": "example_img",
                            "origFileName": "a2img1.png",
                            "fileName": "a2img1.png",
                            "fileSize": 471544,
                            "height": 535,
                            "url": "images/a2img1.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "a2img2.png",
                            "fileName": "a2img2.png",
                            "fileSize": 170347,
                            "height": 535,
                            "url": "images/a2img2.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "a2img3.png",
                            "fileName": "a2img3.png",
                            "fileSize": 200953,
                            "height": 535,
                            "url": "images/a2img3.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "a2img4.png",
                            "fileName": "a2img4.png",
                            "fileSize": 163527,
                            "height": 535,
                            "url": "images/a2img4.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "a2img5.png",
                            "fileName": "a2img5.png",
                            "fileSize": 301331,
                            "height": 535,
                            "url": "images/a2img5.png",
                            "width": 535,
                            "type": "image"
                          }
                        ],
                        "group": ""
                      }
                    </script> -->
                  </a>
                </div>
                <p class="storytitle">
                  Lorem ipsum dolor sit amet, consectetur
                </p>
              </div>
            </div>
            <div class="storyslide w-slide">
              <div class="storyslidewrapper">
                <div class="storyimagediv">
                  <a href="#" class="lightbox-link w-inline-block w-lightbox"
                    ><img
                      src="images/page1.png"
                      loading="lazy"
                      sizes="100vw"
                      srcset="
                        images/page1-p-500.png 500w,
                        images/page1.png       592w
                      "
                      alt=""
                      class="sliderimage"
                    />
                    <!-- <script type="application/json" class="w-json">
                      {
                        "items": [
                          {
                            "_id": "example_img",
                            "origFileName": "page1.png",
                            "fileName": "page1.png",
                            "fileSize": 331411,
                            "height": 732,
                            "url": "images/page1.png",
                            "width": 592,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page2.png",
                            "fileName": "page2.png",
                            "fileSize": 329583,
                            "height": 727,
                            "url": "images/page2.png",
                            "width": 602,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page3.png",
                            "fileName": "page3.png",
                            "fileSize": 361300,
                            "height": 706,
                            "url": "images/page3.png",
                            "width": 581,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page4.png",
                            "fileName": "page4.png",
                            "fileSize": 340994,
                            "height": 708,
                            "url": "images/page4.png",
                            "width": 585,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page5.png",
                            "fileName": "page5.png",
                            "fileSize": 374048,
                            "height": 709,
                            "url": "images/page5.png",
                            "width": 594,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page6.png",
                            "fileName": "page6.png",
                            "fileSize": 307265,
                            "height": 685,
                            "url": "images/page6.png",
                            "width": 590,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page7.png",
                            "fileName": "page7.png",
                            "fileSize": 326497,
                            "height": 703,
                            "url": "images/page7.png",
                            "width": 593,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page8.png",
                            "fileName": "page8.png",
                            "fileSize": 251961,
                            "height": 704,
                            "url": "images/page8.png",
                            "width": 595,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page9.png",
                            "fileName": "page9.png",
                            "fileSize": 314054,
                            "height": 708,
                            "url": "images/page9.png",
                            "width": 611,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page10.png",
                            "fileName": "page10.png",
                            "fileSize": 240401,
                            "height": 695,
                            "url": "images/page10.png",
                            "width": 593,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page11.png",
                            "fileName": "page11.png",
                            "fileSize": 308241,
                            "height": 692,
                            "url": "images/page11.png",
                            "width": 579,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page12.png",
                            "fileName": "page12.png",
                            "fileSize": 298245,
                            "height": 703,
                            "url": "images/page12.png",
                            "width": 576,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page13.png",
                            "fileName": "page13.png",
                            "fileSize": 302759,
                            "height": 701,
                            "url": "images/page13.png",
                            "width": 572,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page14.png",
                            "fileName": "page14.png",
                            "fileSize": 338755,
                            "height": 742,
                            "url": "images/page14.png",
                            "width": 613,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page15.png",
                            "fileName": "page15.png",
                            "fileSize": 331493,
                            "height": 704,
                            "url": "images/page15.png",
                            "width": 583,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page16.png",
                            "fileName": "page16.png",
                            "fileSize": 269092,
                            "height": 693,
                            "url": "images/page16.png",
                            "width": 568,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page17.png",
                            "fileName": "page17.png",
                            "fileSize": 303814,
                            "height": 685,
                            "url": "images/page17.png",
                            "width": 567,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page18.png",
                            "fileName": "page18.png",
                            "fileSize": 295530,
                            "height": 726,
                            "url": "images/page18.png",
                            "width": 605,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "page19.png",
                            "fileName": "page19.png",
                            "fileSize": 284178,
                            "height": 728,
                            "url": "images/page19.png",
                            "width": 596,
                            "type": "image"
                          }
                        ],
                        "group": ""
                      }
                    </script> -->
                  </a>
                </div>
                <p class="storytitle">
                  Lorem ipsum dolor sit amet, consectetur
                </p>
              </div>
            </div>
            <div class="storyslide w-slide">
              <div class="storyslidewrapper">
                <div class="storyimagediv">
                  <a href="#" class="w-inline-block w-lightbox"
                    ><img
                      src="images/img9.png"
                      loading="lazy"
                      sizes="100vw"
                      srcset="images/img9-p-500.png 500w, images/img9.png 535w"
                      alt=""
                      class="sliderimage"
                    />
                    <!-- <script type="application/json" class="w-json">
                      {
                        "items": [
                          {
                            "_id": "example_img",
                            "origFileName": "img9.png",
                            "fileName": "img9.png",
                            "fileSize": 496576,
                            "height": 535,
                            "url": "images/img9.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "img10.png",
                            "fileName": "img10.png",
                            "fileSize": 195337,
                            "height": 535,
                            "url": "images/img10.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "img8.png",
                            "fileName": "img8.png",
                            "fileSize": 191955,
                            "height": 535,
                            "url": "images/img8.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "img7.png",
                            "fileName": "img7.png",
                            "fileSize": 168982,
                            "height": 535,
                            "url": "images/img7.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "img6.png",
                            "fileName": "img6.png",
                            "fileSize": 199232,
                            "height": 535,
                            "url": "images/img6.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "img5.png",
                            "fileName": "img5.png",
                            "fileSize": 183057,
                            "height": 535,
                            "url": "images/img5.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "img4.png",
                            "fileName": "img4.png",
                            "fileSize": 175604,
                            "height": 535,
                            "url": "images/img4.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "img3.png",
                            "fileName": "img3.png",
                            "fileSize": 190593,
                            "height": 535,
                            "url": "images/img3.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "img1.png",
                            "fileName": "img1.png",
                            "fileSize": 202896,
                            "height": 535,
                            "url": "images/img1.png",
                            "width": 535,
                            "type": "image"
                          },
                          {
                            "_id": "example_img",
                            "origFileName": "img2.png",
                            "fileName": "img2.png",
                            "fileSize": 218930,
                            "height": 535,
                            "url": "images/img2.png",
                            "width": 535,
                            "type": "image"
                          }
                        ],
                        "group": ""
                      }
                    </script> -->
                  </a>
                </div>
                <p class="storytitle">
                  Lorem ipsum dolor sit amet, consectetur
                </p>
              </div>
            </div>
          </div>
          <div class="left-arrow-3 w-slider-arrow-left">
            <div class="w-icon-slider-left"></div>
          </div>
          <div class="right-arrow-3 w-slider-arrow-right">
            <div class="w-icon-slider-right"></div>
          </div>
          <div class="slide-nav-2 w-slider-nav w-round w-num"></div>
        </div>
        <Splide
          class="storydiv"
          :options="{
            omitEnd: true,
            focus: 1,
            // gap: 10,
            type: 'slide',
            // drag: 'free',
            arrows: false,
            autoWidth: true,
            pagination: false,
          }"
        >
          <SplideSlide v-for="(item, ind) in storyData" :key="ind">
            <div class="storyslidewrapper-copy">
              <div class="storyimagediv">
                <a
                  href="#"
                  class="lightbox-link w-inline-block w-lightbox"
                  @click="setBox()"
                  ><img
                    :src="item.items[0].url"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 81.66666412353516px, (max-width: 991px) 96.66666412353516px, 116.66666412353516px"
                    alt=""
                    class="sliderimage"
                    :id="'story-' + (ind + 1)"
                  />
                </a>
              </div>
              <p class="storytitle">{{ item.title }}</p>
            </div>

            <!-- <div class="storyslidewrapper-copy">
              <div class="storyimagediv">
                <a href="#" class="lightbox-link w-inline-block" @click="openLightbox(item.items)"
                  ><img
                    :src="item.items[0].url"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 81.66666412353516px, (max-width: 991px) 96.66666412353516px, 116.66666412353516px"
                    alt=""
                    class="sliderimage"
                    :id="'story-' + (ind + 1)"
                  />
                </a>
              </div>
              <p class="storytitle">{{ item.title }}</p>
            </div> -->
          </SplideSlide>
        </Splide>

        <div class="storydiv" style="display: none">
          <div class="storyslidewrapper-copy" v-for="(item, ind) in storyData">
            <div class="storyimagediv">
              <a href="#" class="lightbox-link w-inline-block w-lightbox"
                ><img
                  :src="item.items[0].url"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 81.66666412353516px, (max-width: 991px) 96.66666412353516px, 116.66666412353516px"
                  alt=""
                  class="sliderimage"
                />
              </a>
            </div>
            <p class="storytitle">{{ item.title }}</p>
          </div>
        </div>

        <div
          v-if="false"
          data-delay="4000"
          data-animation="slide"
          class="storybottomslider w-slider"
          data-autoplay="false"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="3"
          data-duration="900"
          data-infinite="true"
        >
          <div class="w-slider-mask">
            <div
              class="storybottomslide w-slide"
              v-for="(item, ind) in news"
              :key="ind"
            >
              <a
                :href="item.id ? '/single-news?id=' + item.id : '#'"
                style="text-decoration: none"
              >
                <div v-if="loading" style="height: 150px; padding: 20px">
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                </div>
                <img
                  v-else
                  :src="item.image"
                  loading="lazy"
                  alt="news"
                  class="bottomimage"
                />

                <div class="bottomcontentwrapper">
                  <h1 href="#" class="bottomtitle">
                    {{ item.title }}
                  </h1>
                  <p v-html="item.content" class="bottomparagraph"></p>
                </div>
              </a>
            </div>
            <!-- <div class="storybottomslide w-slide">
              <a href="/single-news" style="text-decoration: none">
                <img
                  src="images/aafia.jpg"
                  loading="lazy"
                  alt=""
                  class="bottomimage"
                />
                <div class="bottomcontentwrapper">
                  <h1 href="#" class="bottomtitle">
                    Dr Aafia’s lawyer urges more efforts for her return
                  </h1>
                  <p class="bottomparagraph">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra
                  </p>
                </div>
              </a>
            </div>
            <div class="storybottomslide w-slide">
              <a href="/single-news" style="text-decoration: none">
                <img
                  src="images/aafia.jpg"
                  loading="lazy"
                  alt=""
                  class="bottomimage"
                />
                <div class="bottomcontentwrapper">
                  <h1 href="#" class="bottomtitle">
                    Dr Aafia’s lawyer urges more efforts for her return
                  </h1>
                  <p class="bottomparagraph">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra
                  </p>
                </div>
              </a>
            </div>
            <div class="storybottomslide w-slide">
              <a href="/single-news" style="text-decoration: none">
                <img
                  src="images/aafia.jpg"
                  loading="lazy"
                  alt=""
                  class="bottomimage"
                />
                <div class="bottomcontentwrapper">
                  <h1 href="#" class="bottomtitle">
                    Dr Aafia’s lawyer urges more efforts for her return
                  </h1>
                  <p class="bottomparagraph">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra
                  </p>
                </div>
              </a>
            </div>
            <div class="storybottomslide w-slide">
              <a href="/single-news" style="text-decoration: none">
                <img
                  src="images/aafia.jpg"
                  loading="lazy"
                  alt=""
                  class="bottomimage"
                />
                <div class="bottomcontentwrapper">
                  <h1 href="#" class="bottomtitle">
                    Dr Aafia’s lawyer urges more efforts for her return
                  </h1>
                  <p class="bottomparagraph">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra
                  </p>
                </div>
              </a>
            </div> -->
          </div>
          <div class="left-arrow-4 w-slider-arrow-left">
            <div class="w-icon-slider-left"></div>
          </div>
          <div class="right-arrow-4 w-slider-arrow-right">
            <div class="w-icon-slider-right"></div>
          </div>
          <!-- v-if="showDots" -->
          <div
            class="slide-nav-3 w-slider-nav w-slider-nav-invert w-round"
            style="height: fit-content"
          ></div>
        </div>

        <Splide
          class="storybottomslider"
          :options="{
            // rewind: false,
            omitEnd: true,
            focus: 0,
            gap: 15,
            perPage: 4,
            perMove: 1,
            type: 'slide',
            arrows: true,
            breakpoints: {
              991: {
                perPage: 3,
              },
              767: {
                perPage: 2,
              },
              479: {
                perPage: 1,
              },
            },
          }"
        >
          <SplideSlide v-for="(item, ind) in news" :key="ind">
            <div class="storybottomslide">
              <a
                :href="item.id ? '/single-news?id=' + item.id : '#'"
                style="text-decoration: none"
              >
                <div v-if="loading" style="height: 150px; padding: 20px">
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                  <lv-skeleton
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                  <br />
                </div>
                <img
                  v-else
                  :src="item.image"
                  loading="lazy"
                  alt="news"
                  class="bottomimage"
                />

                <div class="bottomcontentwrapper">
                  <h1 href="#" class="bottomtitle">
                    {{ item.title }}
                  </h1>
                  <p v-html="item.content" class="bottomparagraph"></p>
                </div>
              </a>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </section>
    <section class="section wf-section">
      <div class="container w-container">
        <div class="w-layout-grid grid">
          <div
            data-delay="4000"
            data-animation="slide"
            class="articleslider w-slider"
            data-autoplay="false"
            data-easing="ease"
            data-hide-arrows="true"
            data-disable-swipe="false"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="500"
            data-infinite="false"
            id="w-node-f1dc30c2-4d4c-af5c-35d9-d3566444ba65-f079d6dc"
          >
            <div class="articlemask w-slider-mask">
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img9-p-500.png 500w, images/img9.png 535w"
                    src="images/img9.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img10-p-500.png 500w, images/img10.png 535w"
                    src="images/img10.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img8-p-500.png 500w, images/img8.png 535w"
                    src="images/img8.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img7-p-500.png 500w, images/img7.png 535w"
                    src="images/img7.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img6-p-500.png 500w, images/img6.png 535w"
                    src="images/img6.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img5-p-500.png 500w, images/img5.png 535w"
                    src="images/img5.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img4-p-500.png 500w, images/img4.png 535w"
                    src="images/img4.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img3-p-500.png 500w, images/img3.png 535w"
                    src="images/img3.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img1-p-500.png 500w, images/img1.png 535w"
                    src="images/img1.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/img2-p-500.png 500w, images/img2.png 535w"
                    src="images/img2.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
            </div>
            <div class="left-arrow-2 w-slider-arrow-left">
              <div class="icon-2 iconleft w-icon-slider-left"></div>
            </div>
            <div class="right-arrow-2 w-slider-arrow-right">
              <div class="icon-2 iconright w-icon-slider-right"></div>
            </div>
            <div class="slide-nav w-slider-nav w-round w-num"></div>
          </div>
          <div
            data-delay="4000"
            data-animation="slide"
            class="articleslider w-slider"
            data-autoplay="false"
            data-easing="ease"
            data-hide-arrows="true"
            data-disable-swipe="false"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="500"
            data-infinite="false"
            id="w-node-dceecb0e-0f7e-8ecb-5589-791f20e96520-f079d6dc"
          >
            <div class="articlemask w-slider-mask">
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/a2img1-p-500.png 500w,
                      images/a2img1.png       535w
                    "
                    src="images/a2img1.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/a2img2-p-500.png 500w,
                      images/a2img2.png       535w
                    "
                    src="images/a2img2.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/a2img3-p-500.png 500w,
                      images/a2img3.png       535w
                    "
                    src="images/a2img3.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/a2img4-p-500.png 500w,
                      images/a2img4.png       535w
                    "
                    src="images/a2img4.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/a2img5-p-500.png 500w,
                      images/a2img5.png       535w
                    "
                    src="images/a2img5.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
            </div>
            <div class="left-arrow-2 w-slider-arrow-left">
              <div class="icon-2 iconleft w-icon-slider-left"></div>
            </div>
            <div class="right-arrow-2 w-slider-arrow-right">
              <div class="icon-2 iconright w-icon-slider-right"></div>
            </div>
            <div class="slide-nav w-slider-nav w-round w-num"></div>
          </div>
          <div
            data-delay="4000"
            data-animation="slide"
            class="articleslider w-slider"
            data-autoplay="false"
            data-easing="ease"
            data-hide-arrows="true"
            data-disable-swipe="false"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="500"
            data-infinite="false"
            id="w-node-_39b8a949-2fd0-a97e-66a4-4935f5959a4e-f079d6dc"
          >
            <div class="articlemask w-slider-mask">
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/page1-p-500.png 500w, images/page1.png 592w"
                    src="images/page1.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/page2-p-500.png 500w, images/page2.png 602w"
                    src="images/page2.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/page3-p-500.png 500w, images/page3.png 581w"
                    src="images/page3.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/page4-p-500.png 500w, images/page4.png 585w"
                    src="images/page4.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/page5-p-500.png 500w, images/page5.png 594w"
                    src="images/page5.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/page6-p-500.png 500w, images/page6.png 590w"
                    src="images/page6.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/page7-p-500.png 500w, images/page7.png 593w"
                    src="images/page7.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/page8-p-500.png 500w, images/page8.png 595w"
                    src="images/page8.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="images/page9-p-500.png 500w, images/page9.png 611w"
                    src="images/page9.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page10-p-500.png 500w,
                      images/page10.png       593w
                    "
                    src="images/page10.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page11-p-500.png 500w,
                      images/page11.png       579w
                    "
                    src="images/page11.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page12-p-500.png 500w,
                      images/page12.png       576w
                    "
                    src="images/page12.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page13-p-500.png 500w,
                      images/page13.png       572w
                    "
                    src="images/page13.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page14-p-500.png 500w,
                      images/page14.png       613w
                    "
                    src="images/page14.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page15-p-500.png 500w,
                      images/page15.png       583w
                    "
                    src="images/page15.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page16-p-500.png 500w,
                      images/page16.png       568w
                    "
                    src="images/page16.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page17-p-500.png 500w,
                      images/page17.png       567w
                    "
                    src="images/page17.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page18-p-500.png 500w,
                      images/page18.png       605w
                    "
                    src="images/page18.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
              <div class="w-slide">
                <div class="articlediv">
                  <img
                    sizes="100vw"
                    srcset="
                      images/page19-p-500.png 500w,
                      images/page19.png       596w
                    "
                    src="images/page19.png"
                    loading="lazy"
                    alt=""
                    class="articleimg"
                  />
                </div>
              </div>
            </div>
            <div class="left-arrow-2 w-slider-arrow-left">
              <div class="icon-2 iconleft w-icon-slider-left"></div>
            </div>
            <div class="right-arrow-2 w-slider-arrow-right">
              <div class="icon-2 iconright w-icon-slider-right"></div>
            </div>
            <div class="slide-nav w-slider-nav w-round w-num"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="team-slider wf-section">
      <div class="container-2">
        <div style="display: flex; justify-content: space-between">
          <h1 class="centered-heading">
            <span class="highlightheading">Case</span> Videos
          </h1>
          <a href="/videos" class="view-all">view all</a>
        </div>
        <div
          data-delay="5000"
          data-animation="slide"
          class="team-slider-wrapper hideblock w-slider"
          data-autoplay="true"
          data-easing="linear"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="5"
          data-duration="800"
          data-infinite="true"
        >
          <div class="w-slider-mask">
            <div class="team-slide-wrapper w-slide">
              <div class="team-block">
                <div class="div-block">
                  <div
                    style="padding-top: 56.17021276595745%"
                    class="w-embed-youtubevideo youtube"
                  >
                    <iframe
                      src="https://www.youtube.com/embed/YvAGpWds8D8?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                      frameborder="0"
                      style="
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        pointer-events: auto;
                      "
                      allow="autoplay; encrypted-media"
                      allowfullscreen=""
                      title="Aafia Siddiqui&#x27;s plight: 18 Years of Injustice"
                    ></iframe>
                  </div>
                </div>
                <div class="team-block-info">
                  <a href="#" class="titlebutton w-button">Explainers</a>
                  <a href="#" class="text-link-arrow w-inline-block">
                    <div class="text-block">View Playlist</div>
                    <div class="arrow-embed w-embed">
                      <svg
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.72 15L16.3472 10.357C16.7732 9.92932 16.7732 9.23603 16.3472 8.80962L11.72 4.16667L10.1776 5.71508L12.9425 8.4889H4.16669V10.6774H12.9425L10.1776 13.4522L11.72 15Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="team-slide-wrapper w-slide">
              <div class="team-block">
                <div class="div-block">
                  <div
                    style="padding-top: 56.17021276595745%"
                    class="w-embed-youtubevideo youtube"
                  >
                    <iframe
                      src="https://www.youtube.com/embed/Rg6L4yUlnbs?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                      frameborder="0"
                      style="
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        pointer-events: auto;
                      "
                      allow="autoplay; encrypted-media"
                      allowfullscreen=""
                      title="Where is Afia siddiqui ? Ft. Dr. Fauzia | Podcast #22"
                    ></iframe>
                  </div>
                </div>
                <div class="team-block-info">
                  <a href="#" class="titlebutton w-button">Podcasts</a>
                  <a href="#" class="text-link-arrow w-inline-block">
                    <div class="text-block">View Playlist</div>
                    <div class="arrow-embed w-embed">
                      <svg
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.72 15L16.3472 10.357C16.7732 9.92932 16.7732 9.23603 16.3472 8.80962L11.72 4.16667L10.1776 5.71508L12.9425 8.4889H4.16669V10.6774H12.9425L10.1776 13.4522L11.72 15Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="team-slide-wrapper w-slide">
              <div class="team-block">
                <div class="div-block">
                  <div
                    style="padding-top: 56.17021276595745%"
                    class="w-embed-youtubevideo youtube"
                  >
                    <iframe
                      src="https://www.youtube.com/embed/TxHJ0IyKZ2Q?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                      frameborder="0"
                      style="
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        pointer-events: auto;
                      "
                      allow="autoplay; encrypted-media"
                      allowfullscreen=""
                      title="In Search of Prisoner 650 Part 1"
                    ></iframe>
                  </div>
                </div>
                <div class="team-block-info">
                  <a href="#" class="titlebutton w-button">Documentaries</a>
                  <a href="#" class="text-link-arrow w-inline-block">
                    <div class="text-block">View Playlist</div>
                    <div class="arrow-embed w-embed">
                      <svg
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.72 15L16.3472 10.357C16.7732 9.92932 16.7732 9.23603 16.3472 8.80962L11.72 4.16667L10.1776 5.71508L12.9425 8.4889H4.16669V10.6774H12.9425L10.1776 13.4522L11.72 15Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="team-slide-wrapper w-slide">
              <div class="team-block">
                <div class="div-block">
                  <div
                    style="padding-top: 56.17021276595745%"
                    class="w-embed-youtubevideo youtube"
                  >
                    <iframe
                      src="https://www.youtube.com/embed/Dr_qzvyUwqc?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                      frameborder="0"
                      style="
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        pointer-events: auto;
                      "
                      allow="autoplay; encrypted-media"
                      allowfullscreen=""
                      title="My Sister, Aafia | Trailer"
                    ></iframe>
                  </div>
                </div>
                <div class="team-block-info">
                  <a href="#" class="titlebutton w-button">My Sister Aafia</a>
                  <a href="#" class="text-link-arrow w-inline-block">
                    <div class="text-block">View Playlist</div>
                    <div class="arrow-embed w-embed">
                      <svg
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.72 15L16.3472 10.357C16.7732 9.92932 16.7732 9.23603 16.3472 8.80962L11.72 4.16667L10.1776 5.71508L12.9425 8.4889H4.16669V10.6774H12.9425L10.1776 13.4522L11.72 15Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="team-slide-wrapper w-slide">
              <div class="team-block">
                <div class="div-block">
                  <div
                    style="padding-top: 56.17021276595745%"
                    class="w-embed-youtubevideo youtube"
                  >
                    <iframe
                      src="https://www.youtube.com/embed/6UJTswEmR-c?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                      frameborder="0"
                      style="
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        pointer-events: auto;
                      "
                      allow="autoplay; encrypted-media"
                      allowfullscreen=""
                      title="Aafia Siddiqui: Suffering from Psychological Trauma"
                    ></iframe>
                  </div>
                </div>
                <div class="team-block-info">
                  <a href="#" class="titlebutton w-button"
                    >Clive Stafford Smith</a
                  >
                  <a href="#" class="text-link-arrow w-inline-block">
                    <div class="text-block">View Playlist</div>
                    <div class="arrow-embed w-embed">
                      <svg
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.72 15L16.3472 10.357C16.7732 9.92932 16.7732 9.23603 16.3472 8.80962L11.72 4.16667L10.1776 5.71508L12.9425 8.4889H4.16669V10.6774H12.9425L10.1776 13.4522L11.72 15Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="team-slider-arrow vidarrowdivl w-slider-arrow-left">
            <div class="vidarrowleft w-icon-slider-left"></div>
          </div>
          <div class="team-slider-arrow vidarrowdivr w-slider-arrow-right">
            <div class="vidarrowright w-icon-slider-right"></div>
          </div>
          <div
            class="team-slider-nav w-slider-nav w-slider-nav-invert w-round"
          ></div>
        </div>
        <Splide
          class="team-slider-wrapper"
          :options="{
            // rewind: false,
            omitEnd: true,
            focus: 0,
            gap: 10,
            perPage: 3,
            perMove: 1,
            type: 'slide',
            arrows: true,
            breakpoints: {
              991: {
                perPage: 2,
              },
              479: {
                perPage: 1,
              },
            },
          }"
        >
          <SplideSlide v-for="(item, ind) in videoData" :key="ind">
            <div class="team-slide-wrapper">
              <a
                href="#"
                class="videolightbox w-inline-block w-lightbox"
                @click="setBox()"
              >
                <!-- @click="openLightbox(item.items)" -->
                <img
                  :src="item.items[0].thumbnailUrl"
                  loading="lazy"
                  alt=""
                  class="videothumbnail"
                  :id="'video-' + (ind + 1)"
                />
                <div class="playIcon">
                  <img
                    src="images/play-button.png"
                    loading="lazy"
                    alt="play"
                    style="width: 60px"
                  />
                </div>
              </a>
            </div>
          </SplideSlide>
        </Splide>

        <!--         
        <div
          
          data-delay="5000"
          data-animation="slide"
          class="team-slider-wrapper w-slider"
          data-autoplay="true"
          data-easing="linear"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="5"
          data-duration="900"
          data-infinite="true"
        >
          <div class="w-slider-mask">
            <div class="team-slide-wrapper w-slide">
              <a href="#" class="videolightbox w-inline-block w-lightbox">
                <img
                  src="https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg"
                  loading="lazy"
                  alt=""
                  class="videothumbnail"
                  id="video-1"
                />
              </a>
            </div>
            <div class="team-slide-wrapper w-slide">
              <a href="#" class="videolightbox w-inline-block w-lightbox"
                ><img
                  src="https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg"
                  loading="lazy"
                  alt=""
                  class="videothumbnail"
                  id="video-2"
                />
              </a>
            </div>
            <div class="team-slide-wrapper w-slide">
              <a href="#" class="videolightbox w-inline-block w-lightbox"
                ><img
                  src="https://i.ytimg.com/vi/TxHJ0IyKZ2Q/hqdefault.jpg"
                  loading="lazy"
                  alt=""
                  class="videothumbnail"
                  id="video-3"
                />
              </a>
            </div>
            <div class="team-slide-wrapper w-slide">
              <a href="#" class="videolightbox w-inline-block w-lightbox"
                ><img
                  src="https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg"
                  loading="lazy"
                  alt=""
                  class="videothumbnail"
                  id="video-4"
                />
              </a>
            </div>
            <div class="team-slide-wrapper w-slide">
              <a href="#" class="videolightbox w-inline-block w-lightbox"
                ><img
                  src="https://i.ytimg.com/vi/6UJTswEmR-c/hqdefault.jpg"
                  loading="lazy"
                  alt=""
                  class="videothumbnail"
                  id="video-5"
                />
              </a>
            </div>
          </div>
          <div class="team-slider-arrow vidarrowdivl w-slider-arrow-left">
            <div class="vidarrowleft w-icon-slider-left"></div>
          </div>
          <div class="team-slider-arrow vidarrowdivr w-slider-arrow-right">
            <div class="vidarrowright w-icon-slider-right"></div>
          </div>
          <div
            class="team-slider-nav w-slider-nav w-slider-nav-invert w-round"
          ></div>
        </div>
       -->
      </div>
    </section>
    <section class="volunteersection wf-section">
      <div class="volunteer-container">
        <div class="volunteerDiv">
          <h1 class="volunteerheading">Become a Volunteer</h1>
          <h4 class="volunteerdetails">Volunteer for the cause.</h4>
          <a
            href="http://bit.ly/AMACTIVISTS"
            target="_blank"
            class="button-primary-white w-button"
            >Volunteer Now</a
          >
          <!-- https://docs.google.com/forms/d/14QlffvQwKvB10HIi6EY_NS-sqWyBGNv4xuB9OWim9hU/edit -->
        </div>
      </div>
      <div class="volunteerImage">
        <img
          src="images/Untitled design (4).png"
          loading="lazy"
          alt=""
          style="height: 100%; width: 100%; object-fit: cover"
        />
      </div>
    </section>
    <section class="articlesection wf-section" id="articles">
      <div class="container-11 w-container">
        <h1 class="centered-heading">
          <span class="highlightheading">Recent </span>Articles
        </h1>
        <div class="w-layout-grid articlemaingrid">
          <div class="leftdiv">
            <h4 class="leftheading">
              Dr Aafia’s lawyer urges more efforts for her return
            </h4>
            <p
              class="leftparagraph"
              :class="{ 'text-line-clamp expanded': isExpanded }"
            >
              Addressing a press conference here at the Lahore Press Club here
              on Wednesday, he said the Aafia case is the worst example of human
              rights violations, adding that her emotional trauma is even worse
              than that of Guantanamo prisoners.<br />
              He said Dr Aafia has no idea why she is where she is and despite
              being a highly brilliant woman, her life being gradually sucked
              out of her.<br />
              The lawyer said being deprived of all outside communication, is
              like being trapped in an avalanche with no one to call.<br />
              Smith said that he gave Afia hope by telling her that people in
              Pakistan loved her and she expressed her faith in the Pakistani
              people. “I appeal to you not to let her down,” he asked the
              audience. “She is yours and you the keepers of justice, the
              defenders of constitutional rights, need to unite and rise above
              [mutual differences] to support our efforts.”<br />
              Saying that it appears the hurdle to bringing Aafia home are here
              in her own country, he stressed the need to identify and overcome
              these hurdle.<br />
              Smith apologised for the “mistakes” his country (US) made and for
              its “flawed” justice system that resulted in Aafia’s incarceration
              and allowed Guantánamo Bay to continue, though Bagram and Abu
              Gharaib have been shut down because of the efforts of humanitarian
              activists. <br />
              He claimed Aafia’s case is human at its core and universal in
              appeal. <br />
              Aafia Movement leader Dr Fowzia Siddiqui said her sister’s case is
              a big question mark on the state of protection of the rights of
              Pakistani citizens. <br />
              She said it is the most severe torture for a woman to snatch her
              children from her, adding that Afia’s three children were
              separated from her and she had been in detention for the last two
              decades.
            </p>
            <a
              class="read-more"
              v-if="showRead && !isExpanded"
              @click="isExpanded = true"
              >read more</a
            >
            <h4 class="leftauthor">Clive Stafford Smith</h4>
            <h5 class="leftauthortitle">
              British-American Human Rights lawyer
            </h5>
          </div>
          <Splide
            v-if="screenSize"
            class="articleslide"
            :options="{
              // omitEnd: true,
              // focus: 0,
              gap: 10,
              perPage: 1,
              perMove: 1,
              type: 'slide',
              arrows: false,
            }"
          >
            <SplideSlide v-for="(item, ind) in articles" :key="ind">
              <a
                :href="item.id ? '/single-article?id=' + item.id : '#'"
                class="articlecoverdiv w-inline-block"
                :style="{ 'background-image': 'url(' + item.image + ')' }"
              >
                <div class="article-overlay">
                  <h5 class="articledate">
                    Published
                    {{
                      new Date(item.date).toLocaleDateString("en-UK", {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      })
                    }}
                  </h5>
                  <h4 class="articletitle">
                    {{ item.title }}
                  </h4>
                </div>
              </a>
            </SplideSlide>
          </Splide>
          <div
            v-else
            data-delay="4000"
            data-animation="slide"
            class="slider-2 w-slider"
            data-autoplay="false"
            data-easing="ease"
            data-hide-arrows="false"
            data-disable-swipe="false"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="1000"
            data-infinite="true"
            id="w-node-a7f3b9dd-3777-5028-bcd3-0e0a206d4240-f079d6dc"
          >
            <div class="w-slider-mask">
              <div
                class="articleslide w-slide"
                v-for="count in Math.ceil(articles.length / 4)"
                :key="count"
              >
                <div class="w-layout-grid articlegrid">
                  <a
                    v-for="(art, ind) in filteredItems(count)"
                    :key="ind"
                    :href="art.id ? '/single-article?id=' + art.id : '#'"
                    class="articlecoverdiv w-inline-block"
                    :style="{
                      'background-image': 'url(' + art.image + ')',
                    }"
                  >
                    <div class="article-overlay">
                      <h5 class="articledate">
                        Published
                        {{
                          new Date(art.date).toLocaleDateString("en-UK", {
                            year: "numeric",
                            day: "numeric",
                            month: "short",
                          })
                        }}
                      </h5>
                      <h4 class="articletitle">
                        {{ art.title }}
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="left-arrow-5 w-slider-arrow-left">
              <div class="icon-3 icon-adjust w-icon-slider-left"></div>
            </div>
            <div class="right-arrow-5 w-slider-arrow-right">
              <div class="icon-4 icon-adjust w-icon-slider-right"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="team-circles wf-section">
      <div class="container-4">
        <div class="pilldiv">
          <a href="#" class="pillbutton w-button">Great Partners</a>
          <h2 class="centered-heading-2">
            <span class="text-span-2">Our </span>Donors
          </h2>
        </div>
        <div class="team-grid">
          <div
            id="w-node-_9939408e-5e4b-e6cd-216d-65a128fd80c1-f079d6dc"
            class="team-card"
          >
            <div class="teamcontentdiv">
              <img
                src="images/1.jpg"
                loading="lazy"
                alt=""
                class="team-member-image"
              />
              <div class="team-member-position">Executive Director</div>
              <div class="team-member-name">Roberto L. Burleson</div>
              <div class="donatetext">Donated $500</div>
            </div>
            <div class="blackcircle">
              <div class="greencircle"></div>
            </div>
          </div>
          <div
            id="w-node-_31679894-27e6-02bd-1479-e7b1f7dbe8af-f079d6dc"
            class="team-card"
          >
            <div class="teamcontentdiv">
              <img
                src="images/2.jpg"
                loading="lazy"
                alt=""
                class="team-member-image"
              />
              <div class="team-member-position">Executive Director</div>
              <div class="team-member-name">Ellen Kullman</div>
              <div class="donatetext">Donated $500</div>
            </div>
            <div class="blackcircle">
              <div class="greencircle"></div>
            </div>
          </div>
          <div
            id="w-node-cd76de8b-129e-042c-3ba6-b72b9fd3b462-f079d6dc"
            class="team-card"
          >
            <div class="teamcontentdiv">
              <img
                src="images/3.jpg"
                loading="lazy"
                alt=""
                class="team-member-image"
              />
              <div class="team-member-position">Executive Director</div>
              <div class="team-member-name">Devid Paulson</div>
              <div class="donatetext">Donated $500</div>
            </div>
            <div class="blackcircle">
              <div class="greencircle"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="donatesection wf-section">
      <div class="donate-container">
        <div class="donatediv">
          <h1 class="donateheading">Donate Now</h1>
          <h4 class="donatesubheading">
            Donate For The Release Of Dr Aafia Siddiqui
          </h4>
          <a
            @click="toggleDonate('open')"
            data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9"
            href="#"
            class="button-primary-white w-button"
            >Donate Now</a
          >
        </div>
      </div>
      <div class="donateimage">
        <img
          src="images/donate.jpg"
          loading="lazy"
          alt=""
          style="height: 100%; object-fit: cover"
        />
      </div>
    </section>

    <a href="#" class="watchbutton w-button">Watch<br />Intro</a>
    <section class="section-2 wf-section">
      <div class="container-9 w-container">
        <div class="div-block-3">
          <a href="#" class="skipbutton w-button">Skip</a>
        </div>
        <div
          style="padding-top: 56.17021276595745%"
          class="w-embed-youtubevideo introvideo"
        >
          <iframe
            src="https://www.youtube.com/embed/-wbvNCsZP9g?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
            frameborder="0"
            style="
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              pointer-events: auto;
            "
            allow="autoplay; encrypted-media"
            allowfullscreen=""
            title="Afiyet Sıddıki&#x27;yi tanıyor musunuz?‬"
          ></iframe>
        </div>
      </div>
    </section>
    <a
      href="#"
      class="lightbox-link w-inline-block w-lightbox"
      ref="noticeLightbox"
      style="display: none"
      ><img
        :src="noticeData.items[0].url"
        loading="lazy"
        sizes="(max-width: 479px) 100vw, (max-width: 767px) 81.66666412353516px, (max-width: 991px) 96.66666412353516px, 116.66666412353516px"
        alt=""
        class="sliderimage"
        id="notice"
      />
    </a>
    <!-- <section class="donatepopup wf-section" @click="toggleDonate('close')">
      <h1 style="color: white; font-size: 30px" @click.stop>Account Details</h1>
      <div class="donationdiv" @click.stop>
        <h3 class="donationheading">Bank Name</h3>
        <h4 class="donationdetail">HBL</h4>
        <h3 class="donationheading">Title of Account</h3>
        <h4 class="donationdetail">Donation</h4>
        <h3 class="donationheading">Account Number</h3>
        <div style="display: flex; justify-content: space-between">
          <h4 class="donationdetail" id="accountNumber">0044 XXXX XXXX XXXX</h4>
          <img
            @click="copy('account')"
            width="20"
            height="20"
            src="https://img.icons8.com/pastel-glyph/64/copy--v1.png"
            alt="copy--v1"
            style="cursor: pointer"
          />
        </div>
        <h3 class="donationheading">Swift Account</h3>
        <div style="display: flex; justify-content: space-between">
          <h4 class="donationdetail" id="swift">0088 XXXX XXXX XXXX</h4>
          <img
            @click="copy('swift')"
            width="20"
            height="20"
            src="https://img.icons8.com/pastel-glyph/64/copy--v1.png"
            alt="copy--v1"
            style="cursor: pointer"
          />
        </div>
      </div>
      <div data-w-id="44567913-62ce-5393-d531-c4ee0782d899" class="closeicon">
        X
      </div>
    </section> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

import axios from "axios";
import LvSkeleton from "lightvue/skeleton";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
    Splide,
    SplideSlide,
    LvSkeleton,
  },
  data() {
    return {
      showLightbox: false,
      lightboxData: [],
      visibleRef: false,
      indexRef: 0,
      imgsRef: [
        "images/news/92.jpg",
        "images/news/Jehan e pakistan.jpg",
        "images/news/Ummat.jpg",
        "images/a2img1.png",
      ],

      windowSize: window.innerWidth,
      isExpanded: false,
      showRead: false,
      loading: false,
      slideData: [
        {
          quote:
            "Morality Begs Us To Stand Up And Use Our Voice To Ask For Justice With Respect To DR. Aafia Siddiqui.",
          saidBy: "Mike Gravel",
          designation: "Former US Senator",
          desktopImage: "images/webslides/2.jpg",
          mobileImage: "images/webslides/2.jpg",
          textRight: true,
          // desktopImage: "images/Artboard1.png",
          // mobileImage: "images/mobile_images/mike_gravel.png",
        },
        {
          quote: "Aafia Is The Most Wronged Woman On The Planet.",
          saidBy: "Yvonne Ridley",
          designation: "British Journalist",
          desktopImage: "images/webslides/1.jpg",
          // desktopImage: "images/Artboard8.png",
          mobileImage: "images/mobile_images/Ridley image.png",
          textRight: false,
        },
        {
          quote:
            "Dr Aafia siddiqui was victimized by the international politics being played for power.",
          saidBy: "Ramsey Clark",
          designation: "Former U.S Attorney General",
          desktopImage: "images/webslides/3.jpg",
          // desktopImage: "images/Artboard3.png",
          mobileImage: "images/mobile_images/ramsey.png",
          textRight: false,
        },
        // {
        //   quote:
        //     "I heard the screams of a woman in Bagram while the FBI and CIA tortured me. That torture centre has been liberated but Aafia remains in prison on bogus, unrealistic charges.",
        //   saidBy: "Moazzam Begg",
        //   designation: "Ex-detainee Bagram detention centre",
        //   desktopImage: "images/Artboard4.png",
        //   mobileImage: "images/mobile_images/moazzam.png",
        // },
        {
          quote: "Aafia is an institution in herself.",
          saidBy: "Noam Chomsky",
          designation: "American Intellectual",
          desktopImage: "images/webslides/4.jpg",
          // desktopImage: "images/Artboard5.png",
          mobileImage: "images/mobile_images/noam.png",
          textRight: false,
        },
        {
          quote:
            "The Government of Pakistan is not sincere in bringing back Dr. Aafia. Her life is in grave danger. The criminal negligence of Foreign Ministry in Dr. Aafia's case is evident.",
          saidBy: "Talha Mehmood",
          designation: "Senator",
          desktopImage: "images/webslides/5.jpg",
          // desktopImage: "images/Artboard6.png",
          mobileImage: "images/mobile_images/talha.png",
          textRight: true,
        },
        {
          quote:
            "We have received no cooperation from the Government of Pakistan in securing Dr Siddiqui's repatriation to Pakistan, there's been a lot of talk, but no concrete steps have been taken despite numerous requests for assistance.",
          saidBy: "Tina Foster",
          designation: "US Attorney",
          desktopImage: "images/webslides/6.jpg",
          // desktopImage: "images/Artboard7.png",
          mobileImage: "images/mobile_images/tina.png",
          textRight: false,
        },
        {
          quote:
            "If there is an effort made by Pakistan, we could have Aafia back in Karachi within two days.",
          saidBy: "Mike Gravel",
          designation: "Former US Senator",
          desktopImage: "images/webslides/7.jpg",
          // desktopImage: "images/Artboard1.png",
          mobileImage: "images/mobile_images/mike_gravel.png",
          textRight: true,
        },
        {
          quote: "Dr Aafia's case is an fantasy created by the FBI.",
          saidBy: "Yvonne Ridley",
          designation: "British Journalist",
          desktopImage: "images/webslides/8.jpg",
          // desktopImage: "images/Artboard2.png",
          mobileImage: "images/mobile_images/Ridley image-2.png",
          textRight: false,
        },
        {
          quote:
            "I haven't witnessed such bare injustice in my entire career. Neither did she kill, nor did she attempt at. In fact, she was shot thrice. It was an open violation of international laws. I regret that the U.S violated the rules",
          saidBy: "Ramsey Clark",
          designation: "Former U.S Attorney General",
          desktopImage: "images/webslides/9.jpg",
          // desktopImage: "images/Artboard3.png",
          mobileImage: "images/mobile_images/ramsey.png",
          textRight: false,
        },
      ],
      videoData: [
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=-wbvNCsZP9g?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=-wbvNCsZP9g?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/-wbvNCsZP9g/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F-wbvNCsZP9g%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D-wbvNCsZP9g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F-wbvNCsZP9g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FYvAGpWds8D8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DYvAGpWds8D8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FYvAGpWds8D8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=7fMd_2rwVuw?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=7fMd_2rwVuw?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/7fMd_2rwVuw/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F7fMd_2rwVuw%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D7fMd_2rwVuw&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F7fMd_2rwVuw%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },

            // my obj
            // {
            //   url: "https://i.ytimg.com/vi/-wbvNCsZP9g/hqdefault.jpg",
            //   originalUrl: "https://www.youtube.com/embed/-wbvNCsZP9g?autoplay=1",
            //   type: "video",
            // },
            // {
            //   url: "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
            //   originalUrl: "https://www.youtube.com/embed/YvAGpWds8D8?autoplay=1",
            //   type: "video",
            // },
            // {
            //   url: "https://i.ytimg.com/vi/7fMd_2rwVuw/hqdefault.jpg",
            //   originalUrl: "https://www.youtube.com/embed/7fMd_2rwVuw?autoplay=1",
            //   type: "video",
            // },
          ],
          group: "Explainer",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRg6L4yUlnbs%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRg6L4yUlnbs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRg6L4yUlnbs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vnItnUz8B-k/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvnItnUz8B-k%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvnItnUz8B-k&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvnItnUz8B-k%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vmaM7T1AQDU/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvmaM7T1AQDU%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvmaM7T1AQDU&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvmaM7T1AQDU%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/zp7LOtD9wyE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fzp7LOtD9wyE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dzp7LOtD9wyE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fzp7LOtD9wyE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4rKkyivME8w?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=4rKkyivME8w?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4rKkyivME8w/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4rKkyivME8w%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4rKkyivME8w&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4rKkyivME8w%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Podcasts",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=3blBpH1OEZA?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=3blBpH1OEZA?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/3blBpH1OEZA/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F3blBpH1OEZA%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D3blBpH1OEZA&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F3blBpH1OEZA%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=y-p-MRTwang?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=y-p-MRTwang?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/y-p-MRTwang/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fy-p-MRTwang%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dy-p-MRTwang&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fy-p-MRTwang%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=pwC2iRxTIhE?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=pwC2iRxTIhE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/pwC2iRxTIhE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FpwC2iRxTIhE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DpwC2iRxTIhE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FpwC2iRxTIhE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Events",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vnItnUz8B-k/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvnItnUz8B-k%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvnItnUz8B-k&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvnItnUz8B-k%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=WxZ5qVBoHL4?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=WxZ5qVBoHL4?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/WxZ5qVBoHL4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FWxZ5qVBoHL4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWxZ5qVBoHL4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FWxZ5qVBoHL4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vmaM7T1AQDU/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvmaM7T1AQDU%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvmaM7T1AQDU&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvmaM7T1AQDU%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Clive",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FDr_qzvyUwqc%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DDr_qzvyUwqc&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FDr_qzvyUwqc%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/weh4_ICBPPo/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fweh4_ICBPPo%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dweh4_ICBPPo&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fweh4_ICBPPo%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              originalUrl: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/1oAFs8UR5ow/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F1oAFs8UR5ow%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D1oAFs8UR5ow&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F1oAFs8UR5ow%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=Rc5tRN5PId0",
              originalUrl: "https://www.youtube.com/watch?v=Rc5tRN5PId0",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rc5tRN5PId0/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRc5tRN5PId0%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRc5tRN5PId0&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRc5tRN5PId0%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=WxZ5qVBoHL4",
              originalUrl: "https://www.youtube.com/watch?v=WxZ5qVBoHL4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/WxZ5qVBoHL4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FWxZ5qVBoHL4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWxZ5qVBoHL4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FWxZ5qVBoHL4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=q8e5T5Fc_jA",
              originalUrl: "https://www.youtube.com/watch?v=q8e5T5Fc_jA",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/q8e5T5Fc_jA/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fq8e5T5Fc_jA%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dq8e5T5Fc_jA&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fq8e5T5Fc_jA%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=qVp32goZHuM",
              originalUrl: "https://www.youtube.com/watch?v=qVp32goZHuM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/qVp32goZHuM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqVp32goZHuM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqVp32goZHuM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqVp32goZHuM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "TCM Series",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=TxHJ0IyKZ2Q?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=TxHJ0IyKZ2Q?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/TxHJ0IyKZ2Q/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FTxHJ0IyKZ2Q%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DTxHJ0IyKZ2Q&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FTxHJ0IyKZ2Q%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4bbEGxQgzSE?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=4bbEGxQgzSE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4bbEGxQgzSE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4bbEGxQgzSE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4bbEGxQgzSE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4bbEGxQgzSE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Documentaries",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/eOnDBOC5TxQ/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FeOnDBOC5TxQ%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DeOnDBOC5TxQ&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FeOnDBOC5TxQ%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FDr_qzvyUwqc%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DDr_qzvyUwqc&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FDr_qzvyUwqc%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/weh4_ICBPPo/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fweh4_ICBPPo%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dweh4_ICBPPo&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fweh4_ICBPPo%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              originalUrl: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/1oAFs8UR5ow/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F1oAFs8UR5ow%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D1oAFs8UR5ow&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F1oAFs8UR5ow%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRg6L4yUlnbs%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRg6L4yUlnbs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRg6L4yUlnbs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },

            {
              url: "https://www.youtube.com/watch?v=qVp32goZHuM",
              originalUrl: "https://www.youtube.com/watch?v=qVp32goZHuM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/qVp32goZHuM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqVp32goZHuM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqVp32goZHuM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqVp32goZHuM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              originalUrl: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/fkd76_0JnZY/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Ffkd76_0JnZY%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dfkd76_0JnZY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Ffkd76_0JnZY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              originalUrl: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/ZtlySlf0KC4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FZtlySlf0KC4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DZtlySlf0KC4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FZtlySlf0KC4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/zp7LOtD9wyE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fzp7LOtD9wyE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dzp7LOtD9wyE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fzp7LOtD9wyE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Dr Fozia",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              originalUrl: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/fkd76_0JnZY/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Ffkd76_0JnZY%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dfkd76_0JnZY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Ffkd76_0JnZY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/eOnDBOC5TxQ/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FeOnDBOC5TxQ%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DeOnDBOC5TxQ&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FeOnDBOC5TxQ%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              originalUrl: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/ZtlySlf0KC4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FZtlySlf0KC4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DZtlySlf0KC4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FZtlySlf0KC4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=O6KaSYJg-KY",
              originalUrl: "https://www.youtube.com/watch?v=O6KaSYJg-KY",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/O6KaSYJg-KY/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FO6KaSYJg-KY%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DO6KaSYJg-KY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FO6KaSYJg-KY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              originalUrl: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/EguvYXEI0tM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEguvYXEI0tM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEguvYXEI0tM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEguvYXEI0tM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=WBDC_ZZiZpw",
              originalUrl: "https://www.youtube.com/watch?v=WBDC_ZZiZpw",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/WBDC_ZZiZpw/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FWBDC_ZZiZpw%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWBDC_ZZiZpw&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FWBDC_ZZiZpw%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=GdLgb05uYNI",
              originalUrl: "https://www.youtube.com/watch?v=GdLgb05uYNI",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/GdLgb05uYNI/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FGdLgb05uYNI%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DGdLgb05uYNI&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FGdLgb05uYNI%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=r-eI7umWBc8",
              originalUrl: "https://www.youtube.com/watch?v=r-eI7umWBc8",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/r-eI7umWBc8/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fr-eI7umWBc8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dr-eI7umWBc8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fr-eI7umWBc8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              originalUrl: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4RF5mjMGm7g/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4RF5mjMGm7g%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4RF5mjMGm7g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4RF5mjMGm7g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=XX4fBhduF2c",
              originalUrl: "https://www.youtube.com/watch?v=XX4fBhduF2c",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/XX4fBhduF2c/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FXX4fBhduF2c%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DXX4fBhduF2c&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FXX4fBhduF2c%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=7TNCxMkkOow",
              originalUrl: "https://www.youtube.com/watch?v=7TNCxMkkOow",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/7TNCxMkkOow/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F7TNCxMkkOow%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D7TNCxMkkOow&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F7TNCxMkkOow%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "News Channels",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=fyFw7lDS_Ys?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=fyFw7lDS_Ys?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "http://i.ytimg.com/vi/fyFw7lDS_Ys/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FfyFw7lDS_Ys%3Fautoplay%3D1&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DfyFw7lDS_Ys&image=http%3A%2F%2Fi.ytimg.com%2Fvi%2FfyFw7lDS_Ys%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              originalUrl: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/EguvYXEI0tM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEguvYXEI0tM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEguvYXEI0tM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEguvYXEI0tM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=wWWa9MRIV50",
              originalUrl: "https://www.youtube.com/watch?v=wWWa9MRIV50",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/wWWa9MRIV50/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FwWWa9MRIV50%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DwWWa9MRIV50&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FwWWa9MRIV50%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              originalUrl: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4RF5mjMGm7g/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4RF5mjMGm7g%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4RF5mjMGm7g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4RF5mjMGm7g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Imran Khan",
        },
      ],
      news: [
        {
          date: "May 10, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra",
        },
        {
          date: "May 10, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra",
        },
        {
          date: "May 21, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra",
        },
      ],
      articles: [
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 21, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
      ],
      storyData: [
        // {
        //   title: "News",
        //   items: [
        //     {
        //       url: "images/news/92.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Jehan e pakistan.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Ummat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Dunya Express.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/jasarat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Ideal Pakistan.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/press release urdu.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/press release english.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Daily Parliaments.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/nai Baat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Express and jasarat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Dunya Ausaf.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/92 And Nawai Waqt.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Press Release Urdu(1).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Press releaswe English.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.23 PM (2).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.24 PM (1).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.23 PM (3).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/REF NO 051 PR 16 AUG 2023 IHC.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/REF NO 051 PR 16 AUG 2023 ENG IHC.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Nai Baat & Jasarat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/daily parliament.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/REF NO 052 PR 18 AUG 2023 IHC.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Bureaucratic hurdles delaying Aafia Siddiqui case, says lawyer.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-08-19 at 11.38.10 AM.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Ausaf & jasarat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Jehan e pakistan & Nai baat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.25 PM (1).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.25 PM (2).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/daily parliament(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Jehan e pakistan(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Ausaf.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/92(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Express.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Nai Baat(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.28 PM (2).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.28 PM (3).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/ummat(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/daily parliament(2).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Nai Baat(2).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.28 PM (4).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.28 PM (5).jpeg",
        //       type: "image",
        //     },
        //   ],
        // },

        {
          title: "University of Islamic Studies",
          items: [
            {
              url: "images/stories/n1.jpeg",
              type: "image",
            },
            {
              url: "images/stories/n2.jpeg",
              type: "image",
            },
            {
              url: "images/stories/n3.jpeg",
              type: "image",
            },
          ],
        },
        {
          title: "Videos",
          items: [
            {
              url: "https://cdn.cloudious.net/file-1690805918217-816027894.jpg",
              originalUrl: "images/stories/video1.mp4",
              thumbnailUrl:
                "https://cdn.cloudious.net/file-1690805918217-816027894.jpg",
              html: '<iframe class="embedly-embed" src="images/stories/video1.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
              width: 940,
              height: 528,
            },
            {
              url: "images/stories/thumb2.jpeg",
              originalUrl: "images/stories/video2.mp4",
              thumbnailUrl: "images/stories/thumb2.jpeg",
              html: '<iframe class="embedly-embed" src="images/stories/video2.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
              width: 940,
              height: 528,
            },
            {
              url: "images/stories/thumb3.jpeg",
              originalUrl: "images/stories/video3.mp4",
              thumbnailUrl: "images/stories/thumb3.jpeg",
              html: '<iframe class="embedly-embed" src="images/stories/video3.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
              width: 940,
              height: 528,
            },
            {
              url: "images/stories/thumb4.jpeg",
              originalUrl: "images/stories/video4.mp4",
              thumbnailUrl: "images/stories/thumb4.jpeg",
              html: '<iframe class="embedly-embed" src="images/stories/video4.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
              width: 940,
              height: 528,
            },
          ],
          group: "",
        },
        {
          title: "Truth",
          items: [
            {
              _id: "example_img",
              origFileName: "a2img1.png",
              fileName: "a2img1.png",
              fileSize: 471544,
              height: 535,
              url: "images/a2img1.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "a2img2.png",
              fileName: "a2img2.png",
              fileSize: 170347,
              height: 535,
              url: "images/a2img2.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "a2img3.png",
              fileName: "a2img3.png",
              fileSize: 200953,
              height: 535,
              url: "images/a2img3.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "a2img4.png",
              fileName: "a2img4.png",
              fileSize: 163527,
              height: 535,
              url: "images/a2img4.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "a2img5.png",
              fileName: "a2img5.png",
              fileSize: 301331,
              height: 535,
              url: "images/a2img5.png",
              width: 535,
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Aafia’s story",
          items: [
            {
              _id: "example_img",
              origFileName: "page1.png",
              fileName: "page1.png",
              fileSize: 331411,
              height: 732,
              url: "images/page1.png",
              width: 592,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page2.png",
              fileName: "page2.png",
              fileSize: 329583,
              height: 727,
              url: "images/page2.png",
              width: 602,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page3.png",
              fileName: "page3.png",
              fileSize: 361300,
              height: 706,
              url: "images/page3.png",
              width: 581,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page4.png",
              fileName: "page4.png",
              fileSize: 340994,
              height: 708,
              url: "images/page4.png",
              width: 585,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page5.png",
              fileName: "page5.png",
              fileSize: 374048,
              height: 709,
              url: "images/page5.png",
              width: 594,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page6.png",
              fileName: "page6.png",
              fileSize: 307265,
              height: 685,
              url: "images/page6.png",
              width: 590,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page7.png",
              fileName: "page7.png",
              fileSize: 326497,
              height: 703,
              url: "images/page7.png",
              width: 593,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page8.png",
              fileName: "page8.png",
              fileSize: 251961,
              height: 704,
              url: "images/page8.png",
              width: 595,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page9.png",
              fileName: "page9.png",
              fileSize: 314054,
              height: 708,
              url: "images/page9.png",
              width: 611,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page10.png",
              fileName: "page10.png",
              fileSize: 240401,
              height: 695,
              url: "images/page10.png",
              width: 593,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page11.png",
              fileName: "page11.png",
              fileSize: 308241,
              height: 692,
              url: "images/page11.png",
              width: 579,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page12.png",
              fileName: "page12.png",
              fileSize: 298245,
              height: 703,
              url: "images/page12.png",
              width: 576,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page13.png",
              fileName: "page13.png",
              fileSize: 302759,
              height: 701,
              url: "images/page13.png",
              width: 572,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page14.png",
              fileName: "page14.png",
              fileSize: 338755,
              height: 742,
              url: "images/page14.png",
              width: 613,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page15.png",
              fileName: "page15.png",
              fileSize: 331493,
              height: 704,
              url: "images/page15.png",
              width: 583,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page16.png",
              fileName: "page16.png",
              fileSize: 269092,
              height: 693,
              url: "images/page16.png",
              width: 568,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page17.png",
              fileName: "page17.png",
              fileSize: 303814,
              height: 685,
              url: "images/page17.png",
              width: 567,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page18.png",
              fileName: "page18.png",
              fileSize: 295530,
              height: 726,
              url: "images/page18.png",
              width: 605,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page19.png",
              fileName: "page19.png",
              fileSize: 284178,
              height: 728,
              url: "images/page19.png",
              width: 596,
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Myths vs Facts",
          items: [
            {
              url: "images/stories/myth1.png",
              type: "image",
            },
            {
              url: "images/stories/myth2.png",
              type: "image",
            },
            {
              url: "images/stories/myth3.png",
              type: "image",
            },
            {
              url: "images/stories/myth4-1.png",
              type: "image",
            },
            {
              url: "images/stories/myth4-2.png",
              type: "image",
            },
            {
              url: "images/stories/myth5.png",
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Testimonials",
          items: [
            {
              url: "images/stories/1.png",
              type: "image",
            },
            {
              url: "images/stories/2.png",
              type: "image",
            },
            {
              url: "images/stories/3.png",
              type: "image",
            },
            {
              url: "images/stories/4.png",
              type: "image",
            },
            {
              url: "images/stories/5.png",
              type: "image",
            },
            {
              url: "images/stories/6.png",
              type: "image",
            },
            {
              url: "images/stories/7.png",
              type: "image",
            },
            {
              url: "images/stories/8.png",
              type: "image",
            },
            {
              url: "images/stories/9.png",
              type: "image",
            },
            {
              url: "images/stories/10.png",
              type: "image",
            },
            {
              url: "images/stories/11.png",
              type: "image",
            },
            {
              url: "images/stories/12.png",
              type: "image",
            },
            {
              url: "images/stories/13.png",
              type: "image",
            },
            {
              url: "images/stories/14.png",
              type: "image",
            },
            {
              url: "images/stories/15.png",
              type: "image",
            },
            {
              url: "images/stories/16.png",
              type: "image",
            },
            {
              url: "images/stories/17.png",
              type: "image",
            },
            {
              url: "images/stories/18.png",
              type: "image",
            },
            {
              url: "images/stories/19.png",
              type: "image",
            },
            {
              url: "images/stories/20.png",
              type: "image",
            },
            {
              url: "images/stories/21.png",
              type: "image",
            },
            {
              url: "images/stories/22.png",
              type: "image",
            },
            {
              url: "images/stories/23.png",
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Clive S Smith",
          items: [
            {
              url: "images/stories/3.png",
              type: "image",
            },
            {
              url: "images/stories/4.png",
              type: "image",
            },
            {
              url: "images/stories/5.png",
              type: "image",
            },
            {
              url: "images/stories/6.png",
              type: "image",
            },
            {
              url: "images/stories/7.png",
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Yvonne’s Discovery",
          items: [
            {
              _id: "example_img",
              origFileName: "img9.png",
              fileName: "img9.png",
              fileSize: 496576,
              height: 535,
              url: "images/img9.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img10.png",
              fileName: "img10.png",
              fileSize: 195337,
              height: 535,
              url: "images/img10.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img8.png",
              fileName: "img8.png",
              fileSize: 191955,
              height: 535,
              url: "images/img8.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img7.png",
              fileName: "img7.png",
              fileSize: 168982,
              height: 535,
              url: "images/img7.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img6.png",
              fileName: "img6.png",
              fileSize: 199232,
              height: 535,
              url: "images/img6.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img5.png",
              fileName: "img5.png",
              fileSize: 183057,
              height: 535,
              url: "images/img5.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img4.png",
              fileName: "img4.png",
              fileSize: 175604,
              height: 535,
              url: "images/img4.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img3.png",
              fileName: "img3.png",
              fileSize: 190593,
              height: 535,
              url: "images/img3.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img1.png",
              fileName: "img1.png",
              fileSize: 202896,
              height: 535,
              url: "images/img1.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img2.png",
              fileName: "img2.png",
              fileSize: 218930,
              height: 535,
              url: "images/img2.png",
              width: 535,
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "My Sister Aafia",
          items: [
            {
              url: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              // originalUrl: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              originalUrl:
                "https://www.youtube.com/embed/Dr_qzvyUwqc?autoplay=1",
              // originalUrl: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FDr_qzvyUwqc%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DDr_qzvyUwqc&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FDr_qzvyUwqc%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "",
        },
        {
          title: "Aafia Siddiqui’s plight",
          items: [
            {
              url: "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
              // originalUrl: "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
              originalUrl:
                "https://www.youtube.com/embed/YvAGpWds8D8?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FYvAGpWds8D8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DYvAGpWds8D8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FYvAGpWds8D8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "",
        },
        {
          title: "Where is Dr. Aafia?",
          items: [
            {
              url: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              // originalUrl: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              originalUrl:
                "https://www.youtube.com/embed/Rg6L4yUlnbs?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRg6L4yUlnbs%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRg6L4yUlnbs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRg6L4yUlnbs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "",
        },
        // {
        //   title: "Videos",
        //   items: [
        //     {
        //       url: "https://i.ytimg.com/vi/6UJTswEmR-c/hqdefault.jpg",
        //       originalUrl: "images/stories/test.mp4",
        //       thumbnailUrl: "https://i.ytimg.com/vi/6UJTswEmR-c/hqdefault.jpg",
        //       html: '<iframe class="embedly-embed" src="images/stories/test.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
        //       type: "video",
        //       width: 940,
        //       height: 528,
        //     },
        //   ],
        //   group: "",
        // },
      ],

      first: true,
      targetElement: null,
      noticeData: {
        items: [
          // {
          //   url: "images/notice/daughter-of-nation1.jpg",
          //   type: "image",
          // },
          // {
          //   url: "images/notice/sister1.jpg",
          //   type: "image",
          // },
          // {
          //   url: "images/notice/aafia-calls-nation.jpg",
          //   type: "image",
          // },
          // {
          //   url: "images/notice/aafia-calls-you.jpg",
          //   type: "image",
          // },
          {
            url: "images/notice/notice.jpeg",
            type: "image",
          },
        ],
        group: "notice",
      },
      noticeTill: "2023-09-24",
    };
  },
  mounted() {
    // console.log(this.showDots());
    window.addEventListener("resize", this.handleResize);

    if (new Date() < new Date(this.noticeTill)) {
      this.setNotice();
    }

    this.setStories();
    this.setPara();

    // this.loadStories();
    this.setSLightbox();

    this.videoData.forEach((el, ind) => {
      let script = document.createElement("script");
      script.type = "application/json";
      script.classList.add("w-json");

      script.text = JSON.stringify(el);

      const target = document.getElementById(`video-${ind + 1}`);
      target.parentNode.insertBefore(script, target.nextSibling);
    });

    window.addEventListener("popstate", function (event) {
      // console.log("Previous state: ", event);
      const cross = document.querySelector(".w-lightbox-close");
      if (cross) {
        cross.click();
      }
      // window.history.replaceState(null, null, window.location.pathname);
    });
  },
  created() {
    this.loadData();
    // this.loadStories();
    // if (this.$store.state.slides.length == 0) {
    //   this.$store.commit("setSlides", this.slideData);
    // }
    this.$store.commit("setSlides", this.slideData);
    // this.loadSlides();
    // console.log(window.history, window.location);
  },
  computed: {
    screenSize() {
      return this.windowSize <= 479;
      // if (window.innerWidth <= 479) {
      //   if (this.articles.length > 4) this.isExpanded = true;
      //   else this.isExpanded = false;
      //   return this.articles.slice(0, 4);
      // } else {
      //   return this.articles;
      // }
    },
    showDots() {
      const size = this.windowSize;
      console.log(size);
      if (size > 991) {
        if (this.news.length > 4) return true;
        else return false;
      } else if (size > 796) {
        if (this.news.length > 3) return true;
        else return false;
      } else if (size > 479) {
        if (this.news.length > 2) return true;
        else return false;
      } else if (size <= 479) {
        if (this.news.length > 1) return true;
        else return false;
      } else return false;
    },
  },
  methods: {
    openLightbox(items) {
      this.lightboxData = items;
      this.showLightbox = true;
      setTimeout(() => {
        this.$refs.main.sync(this.$refs.thumbnails.splide);
        // stop all autoplay except for first
        let allTags = document.querySelectorAll(".lightbox-iframe");
        allTags.forEach((el, ind) => {
          if (ind != 0) el.src = "";
        });
      }, 200);
    },
    handleMove(e, newIndex, prevIndex) {
      let prevTag = document.getElementById(`iframe${prevIndex}`);
      if (prevTag) {
        prevTag.src = "";
      }
      let nextTag = document.getElementById(`iframe${newIndex}`);
      if (nextTag) {
        nextTag.src = this.lightboxData[newIndex].originalUrl;
      }
    },
    onHide() {
      this.visibleRef = false;
    },
    show() {
      this.visibleRef = true;
    },
    setNotice() {
      let script = document.createElement("script");
      script.type = "application/json";
      script.classList.add("w-json");

      script.text = JSON.stringify(this.noticeData);

      const target = document.getElementById("notice");
      target.parentNode.insertBefore(script, target.nextSibling);
      setTimeout(() => {
        this.$refs.noticeLightbox.click();
      }, 200);
    },
    setBox() {
      window.history.pushState(null, null, window.location.pathname);

      // console.log(window.history);
      // setTimeout(() => {
      // if (this.first) {
      //   this.createOb();
      //   this.first = false;
      // }
      // }, 200);
    },
    createOb() {
      // Function to check if element is a valid DOM element
      const isValidElement = (element) => {
        return element instanceof Element || element instanceof HTMLDocument;
      };

      // Target element you want to observe
      this.targetElement = document.querySelector(".w-lightbox-backdrop");
      // Check if the targetElement is valid
      if (isValidElement(this.targetElement)) {
        // Callback function to be executed when the element's opacity changes

        const observer = new MutationObserver(this.opacityChangedCallback);
        const config = { attributes: true };

        // Start observing the target element
        observer.observe(this.targetElement, config);
      } else {
        console.error("Invalid target element or not found in the DOM.");
      }
    },
    opacityChangedCallback(mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "style"
        ) {
          // Check if the element's opacity is 1 (hidden)
          const currentOpacity = parseFloat(this.targetElement.style.opacity);
          if (currentOpacity === 0) {
            // Run your function here when the element's opacity is 1
            window.history.pushState(null, null, window.location.pathname);
            console.log(window.history);
          }
        }
      }
    },
    handleResize() {
      this.windowSize = window.innerWidth;
      // console.log("ss");
    },
    setStories() {
      if (window.innerWidth <= 479) {
        const blockA = document.querySelector(".storydiv");
        const section5 = document.getElementById("main-slider");
        section5.parentNode.insertBefore(blockA, section5);
      }
    },
    setPara() {
      const element = document.querySelector(".leftparagraph");
      const originalHeight = element.offsetHeight;
      element.classList.add("text-line-clamp");
      const lineClampHeight = element.offsetHeight;

      // console.log(element, originalHeight, lineClampHeight);
      this.showRead = lineClampHeight <= originalHeight; //  true line-clamp applied
    },
    setSLightbox() {
      // console.log(this.storyData);
      this.storyData.forEach((el, ind) => {
        // Create a new script element
        let script = document.createElement("script");
        script.type = "application/json";
        script.classList.add("w-json");
        // Set the content of the script element
        script.text = JSON.stringify(el);

        const target = document.getElementById(`story-${ind + 1}`);
        target.parentNode.insertBefore(script, target.nextSibling);
      });
    },
    filteredItems(ind) {
      return this.articles.slice((ind - 1) * 4, ind * 4);
    },

    toggleDonate(text) {
      const sec = document.getElementsByClassName("donatepopup");
      sec[0].style.display = text == "open" ? "flex" : "none";
    },

    getThumbnail(link) {
      const id = link.split("v=")[1];
      return `https://img.youtube.com/vi/${id}/mqdefault.jpg`;
    },

    loadData() {
      // console.log(this.$store.state.articles);
      // console.log(this.$store.state.news);
      this.loading = true;

      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/News",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.news = response.data.data;
          this.news.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          this.$store.commit("setNews", this.news);
          this.loading = false;
          // console.log(this.$store.state.news);
        })
        .catch(function (error) {
          // console.log(error);
        });

      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/Articles",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.articles = response.data.data.reverse();
          this.$store.commit("setArticles", this.articles);
          // console.log(this.$store.state.articles);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    loadSlides() {
      var config2 = {
        method: "get",
        url: "https://aafia.cloudious.net/api/Slider",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config2)
        .then((response) => {
          this.slideData = response.data.data;
          this.$store.commit("setSlides", this.slideData);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadStories() {
      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/Stories",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          let res = response.data.data;
          // console.log(this.items);
          this.storyData = res.map((el) => {
            return {
              items: JSON.parse(el.images),
              // items: [{ url: "images/notice/notice.jpeg", type: "image" }],
              title: el.title,
            };
          });
          // console.log(this.storyData);
          console.log(this.storyData);
          // this.setSLightbox();
          // this.storyData.forEach((el, ind) => {
          //   // Create a new script element
          //   let script = document.createElement("script");
          //   script.type = "application/json";
          //   script.classList.add("w-json");
          //   console.log(el);
          //   // Set the content of the script element
          //   script.text = JSON.stringify(el);

          //   const target = document.getElementById(`story-${ind + 1}`);
          //   target.parentNode.insertBefore(script, target.nextSibling);
          // });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- <script type="application/json" class="w-json">
{
  "items": [
    {
      "url": "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
      "originalUrl": "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
      "width": 940,
      "height": 528,
      "thumbnailUrl": "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
      "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FYvAGpWds8D8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DYvAGpWds8D8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FYvAGpWds8D8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
      "type": "video"
    }
  ],
  "group": "case-videos"
}
</script> -->

<style lang="css">
.splide__list {
  /* transition: all 1s ease 0s; */
  transition: transform 900ms ease 0s;
}

.splide__pagination {
  bottom: unset;
  position: static;
  margin-top: 10px;
}
.splide__pagination__page {
  background-color: rgba(34, 34, 34, 0.4);
}
.splide__pagination__page.is-active {
  background: #198754;
  transform: none;
}

.splide__arrow {
  background: transparent;
}
.splide__arrow svg {
  fill: hsla(0, 0%, 93.3%, 0.9);
}

.storybottomslider .splide__list {
  padding-bottom: 10px !important;
}
.storybottomslider .splide__arrow {
  top: calc(50% - 18px);
}

#main-slider .splide__list {
  min-height: 73vh;
}
#main-slider .splide__pagination {
  position: absolute;
  bottom: 65px;
  height: 40px;
}
#main-slider .splide__pagination__page {
  background: rgba(255, 255, 255, 0.4);
}
#main-slider .splide__pagination__page.is-active {
  background: #198754;
  transform: scale(1.4);
}

.my-lightbox .splide__track {
  height: 100%;
}
.my-lightbox .splide__arrow svg {
  width: 4rem;
  height: 4rem;
}
.my-lightbox-thumbnail .splide__track--nav > .splide__list > .splide__slide {
  opacity: 1;
}
.my-lightbox .splide__track--nav > .splide__list > .splide__slide.is-active {
  border-color: transparent;
  opacity: 0.3;
}

.setRight {
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .setRight {
    margin-left: 0;
  }

  .my-lightbox .splide__arrow {
    display: none;
  }
}
@media screen and (max-width: 479px) {
  #main-slider .splide__pagination {
    bottom: 45px;
  }
}
</style>
